/* General styles */
body {
  margin: 0;
  padding: 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #000;
}

/* Company Dashboard */
.company-dashboard {
  max-width: 1200px;
  margin: 0 auto;
}

/* Sections */
.wallet-section,
.payroll-section,
.logs-section {
  margin: 20px 0;
  padding: 20px;
  border: 1px solid #eee;
  border-radius: 4px;
}

/* Forms and inputs */
input {
  margin: 5px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

button {
  margin: 5px;
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

/* Sections within wallet */
.init-section,
.actions-section,
.send-section,
.settle-section {
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
}

/* Payroll confirmation table */
.payroll-confirmation {
  margin: 20px 0;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.payroll-confirmation table {
  width: 100%;
  border-collapse: collapse;
  margin: 10px 0;
}

.payroll-confirmation th,
.payroll-confirmation td {
  padding: 8px;
  border: 1px solid #ddd;
  text-align: left;
}

.payroll-confirmation th {
  background-color: #f5f5f5;
}

.payroll-confirmation .total {
  margin: 10px 0;
  font-weight: bold;
  text-align: right;
}

.confirmation-actions {
  margin-top: 20px;
  text-align: right;
}

/* Logs textarea */
.logs-section textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #e6f0ff;
  border-radius: 4px;
  font-family: monospace;
  background-color: #f8fbff;
  color: #000;
  margin-top: 10px;
}

/* Responsive design */
@media (max-width: 768px) {
  .company-dashboard {
    padding: 10px;
  }
  
  input, button {
    width: 100%;
    margin: 5px 0;
  }
  
  .payroll-confirmation {
    overflow-x: auto;
  }
}

/* Add these styles to your existing index.css */

.payroll-dashboard {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.payroll-controls {
  margin: 20px 0;
  padding: 20px;
  background-color: #f0f7ff;
  border-radius: 4px;
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
}

.payroll-table {
  margin: 20px 0;
  padding: 20px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.payroll-table table {
  width: 100%;
  border-collapse: collapse;
  margin: 10px 0;
}

.payroll-table th,
.payroll-table td {
  padding: 8px;
  border: 1px solid #ddd;
  text-align: left;
}

.payroll-table th {
  background-color: #f5f5f5;
}

.status-pending {
  background-color: #fff3cd;
}

.status-success {
  background-color: #d4edda;
}

.status-failed {
  background-color: #f8d7da;
}

.total {
  margin: 10px 0;
  font-weight: bold;
  text-align: right;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Add these styles to your existing index.css */

.login-container {
  max-width: 400px;
  margin: 100px auto;
  padding: 30px;
  border: 1px solid #e6f0ff;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.login-container h2 {
  text-align: center;
  margin-bottom: 30px;
  color: #000;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.login-form .form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.login-form .form-group label {
  font-weight: 500;
  color: #000;
}

.login-form input {
  width: 100%;
  padding: 12px;
  border: 1px solid #e6f0ff;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.2s;
}

.login-form input:focus {
  border-color: #007bff;
  outline: none;
}

.login-form button {
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
}

.login-form button:hover {
  background-color: #0056b3;
}

.role-switch {
  margin-bottom: 10px;
  padding: 15px;
  background-color: #f0f7ff;
  border-radius: 4px;
}

.role-switch label {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  font-weight: 500;
  color: #000;
}

.role-switch input[type="checkbox"] {
  width: auto;
  margin: 0;
  cursor: pointer;
}

/* Add to your existing styles */

.error-message {
  margin: 10px 0;
  padding: 10px;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
  color: #721c24;
}

.cleanup-message {
  max-width: 400px;
  margin: 100px auto;
  padding: 20px;
  text-align: center;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
  color: #721c24;
}

/* Add these styles to your existing CSS */

.tabs {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.tabs button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background: #cce5ff;
  color: #000;
  cursor: pointer;
  font-weight: 500;
}

.tabs button.active {
  background: #007bff;
  color: white;
}

.tabs button:hover:not(.active) {
  background: #b8daff;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 4px;
  min-width: 300px;
}

.modal-actions {
  display: flex;
  gap: 10px;
  margin-top: 20px;
  justify-content: flex-end;
}

.address-display, .balance-display {
  margin: 20px 0;
  padding: 20px;
  background: #f0f7ff;
  border-radius: 4px;
  color: #000;
}

.address-item, .balance-item {
  margin: 10px 0;
}

.address-item code {
  display: block;
  padding: 10px;
  background: #fff;
  border: 1px solid #e6f0ff;
  border-radius: 4px;
  word-break: break-all;
  color: #000;
}

.bip21-string {
  font-size: 0.9em;
  color: #000;
}

.wallet-actions {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

/* Add to your existing styles */

.qr-section {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  padding: 20px;
  background: white;
  border-radius: 4px;
}

.address-input {
  display: flex;
  align-items: center;
  gap: 8px;
}

.address-input input {
  flex: 1;
  font-family: monospace;
  font-size: 14px;
  padding: 8px;
  background: white;
  border: 1px solid #e6f0ff;
  border-radius: 4px;
  color: #000;
  cursor: pointer;
  transition: all 0.2s;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.address-input input:hover {
  background-color: #f8f9fa;
}

.copy-button {
  padding: 8px;
  min-width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.copy-button:hover {
  background: #0056b3;
}

/* Update existing styles */
.address-item {
  margin: 20px 0;
}

.address-item h4 {
  margin-bottom: 10px;
}

/* Add to your existing styles */

.collapsible-section {
  margin: 20px 0;
  border: 1px solid #e6f0ff;
  border-radius: 4px;
  overflow: hidden;
}

.collapsible-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background: #f0f7ff;
  cursor: pointer;
  user-select: none;
}

.collapsible-header:hover {
  background: #e6f0ff;
}

.collapsible-header h3 {
  margin: 0;
  color: #000;
}

.toggle-icon {
  font-size: 12px;
  color: #007bff;
}

.collapsible-content {
  padding: 20px;
  background: white;
}

/* Update existing styles */
.address-display, .balance-display {
  margin: 0;
  padding: 0;
  background: transparent;
}

/* Add to your existing styles */

.settings-tab {
  margin-top: 20px;
}

.settings-form {
  padding: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #000;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #e6f0ff;
  border-radius: 4px;
  font-size: 14px;
  color: #000;
}

.settings-form button {
  width: 100%;
  margin-top: 20px;
}

/* Add these styles */
.balance-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.icon-button {
  background: none;
  border: none;
  padding: 5px;
  font-size: 20px;
  cursor: pointer;
  color: #007bff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: background-color 0.2s;
}

.icon-button:hover {
  background-color: rgba(0, 123, 255, 0.1);
}

.balance-actions {
  display: flex;
  gap: 10px;
  margin: 15px 0;
}

/* Update existing styles */
.balance-display {
  padding: 20px;
}

.balance-item {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #e6f0ff;
}

/* Remove the wallet-actions class since we don't need it anymore */

/* Add to your existing modal styles */

.modal-input-group {
  margin-bottom: 15px;
}

.modal-input-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
  color: #000;
}

.modal-input-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid #e6f0ff;
  border-radius: 4px;
  font-size: 14px;
}

.modal-content {
  background: white;
  padding: 25px;
  border-radius: 4px;
  min-width: 400px;
  max-width: 90%;
}

.modal-content h3 {
  margin-top: 0;
  margin-bottom: 20px;
  color: #000;
}

/* Add to your existing styles */

.tab-content {
  margin-bottom: 20px;
}

.csv-instructions {
  margin-bottom: 20px;
  padding: 20px;
  background: #f0f7ff;
  border-radius: 4px;
}

.csv-instructions h3 {
  margin-top: 0;
  color: #000;
}

.csv-instructions ul {
  margin: 15px 0;
  padding-left: 20px;
}

.csv-instructions li {
  margin: 8px 0;
  color: #000;
}

.csv-template {
  margin-top: 15px;
  padding: 15px;
  background: white;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 15px;
}

.csv-template code {
  padding: 8px;
  background: #f8f9fa;
  border-radius: 4px;
  font-family: monospace;
  color: #000;
}

/* Update address display styles */
.addresses-list {
  margin-top: 20px;
}

.address-row {
  margin-bottom: 15px;
  padding: 10px;
  background: white;
  border-radius: 4px;
  border: 1px solid #e6f0ff;
}

.address-label {
  font-weight: 500;
  margin-bottom: 8px;
  color: #000;
}

.address-value {
  display: flex;
  gap: 8px;
  align-items: flex-start;
}

.address-text {
  flex: 1;
  font-family: monospace;
  font-size: 14px;
  padding: 8px;
  background: #f8f9fa;
  border-radius: 4px;
  word-break: break-all;
  line-height: 1.4;
}

.qr-section {
  display: flex;
  justify-content: center;
  padding: 20px;
  background: white;
  border-radius: 4px;
  border: 1px solid #e6f0ff;
}

/* Add to your existing styles */
.logs-header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.logs-header .icon-button {
  font-size: 16px;
  width: 32px;
  height: 32px;
}

/* Add to your existing modal styles */

.modal-logs {
  margin: 15px 0;
  padding: 10px;
  background: #f8f9fa;
  border: 1px solid #e6f0ff;
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
}

.modal-log-line {
  padding: 4px 0;
  font-family: monospace;
  font-size: 14px;
  color: #000;
}

.modal-loader {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.modal-content {
  min-width: 450px;
}

/* Add to your existing styles */

.secret-input-group {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.secret-input-group input {
  flex: 1;
  padding: 10px;
  border: 1px solid #e6f0ff;
  border-radius: 4px;
  font-size: 14px;
}

.secret-input-group .icon-button {
  padding: 8px;
  width: 36px;
  height: 36px;
  min-width: 36px;
  font-size: 16px;
  margin: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Update form group styles */
.form-group {
  margin-bottom: 20px;
  width: 100%;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #000;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #e6f0ff;
  border-radius: 4px;
  font-size: 14px;
}

/* Add to your existing styles */
.secret-format {
  margin-top: 10px;
  padding: 10px;
  background: #f8f9fa;
  border-radius: 4px;
  font-size: 14px;
}

.format-row {
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 5px 0;
}

.format-row span {
  min-width: 50px;
  font-weight: 500;
}

.format-row code {
  flex: 1;
  padding: 4px 8px;
  background: white;
  border: 1px solid #e6f0ff;
  border-radius: 4px;
  font-family: monospace;
  word-break: break-all;
}

/* Add to your CSV styles */
.csv-examples {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.csv-examples code {
  display: block;
  padding: 8px;
  background: #f8f9fa;
  border-radius: 4px;
  font-family: monospace;
  color: #000;
  font-size: 12px;
  word-break: break-all;
}

/* Add these styles for payroll history */
.payroll-history {
  margin-top: 20px;
}

.payroll-history table {
  width: 100%;
  border-collapse: collapse;
  margin: 10px 0;
}

.payroll-history th,
.payroll-history td {
  padding: 12px;
  border: 1px solid #e6f0ff;
  text-align: left;
}

.payroll-history th {
  background-color: #f0f7ff;
  font-weight: 500;
}

.payroll-history tr:hover {
  background-color: #f8fbff;
}

.payroll-tab h2 {
  margin-bottom: 20px;
  color: #000;
}

/* Update existing collapsible section styles */
.collapsible-section {
  margin-bottom: 20px;
}

.collapsible-section:last-child {
  margin-bottom: 0;
}

/* Update CSV instructions styles */
.csv-instructions {
  margin: 0;
  padding: 15px;
}

.csv-template {
  margin-top: 15px;
  padding: 15px;
  background: #f8fbff;
  border-radius: 4px;
}

/* Add these styles for TSV export */
.payroll-history td button.icon-button {
  padding: 4px;
  width: 28px;
  height: 28px;
  min-width: 28px;
  font-size: 14px;
}

.payroll-history td {
  vertical-align: middle;
}

/* Update status colors */
.status-completed {
  background-color: #d4edda;
}

.status-partial {
  background-color: #fff3cd;
}

.status-failed {
  background-color: #f8d7da;
}

/* Add these styles for the export section */
.export-section {
  padding: 20px;
  background: #f8fbff;
  border-radius: 4px;
}

.export-section p {
  margin-bottom: 15px;
  color: #000;
}

.export-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  padding: 12px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
}

.export-button:hover {
  background-color: #218838;
}

.export-button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

/* Add these styles for transaction history */
.transaction-history {
  margin-top: 20px;
}

.transaction-history table {
  width: 100%;
  border-collapse: collapse;
  margin: 10px 0;
}

.transaction-history th,
.transaction-history td {
  padding: 12px;
  border: 1px solid #e6f0ff;
  text-align: left;
}

.transaction-history th {
  background-color: #f0f7ff;
  font-weight: 500;
}

.transaction-history tr:hover {
  background-color: #f8fbff;
}

.transaction-history .type-received {
  background-color: #d4edda;
}

.transaction-history .type-sent {
  background-color: #f8f9fa;
}

.transaction-history .amount-positive {
  color: #28a745;
  font-weight: 500;
}

.transaction-history .amount-negative {
  color: #dc3545;
  font-weight: 500;
}

.transaction-history .tx-id {
  font-family: monospace;
  font-size: 14px;
}

.transaction-history td.status-pending {
  color: #856404;
  background-color: #fff3cd;
}

/* Update the logs section styles to fit within settings tab */
.logs-section {
  margin-top: 10px;
}

.logs-section textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #e6f0ff;
  border-radius: 4px;
  font-family: monospace;
  background-color: #f8fbff;
  color: #000;
  margin-top: 10px;
}

.settings-tab .collapsible-section {
  margin-bottom: 20px;
}

.settings-tab .collapsible-section:last-child {
  margin-bottom: 0;
}

/* Add styles for the help button */
.help-button {
  background-color: #6c757d;
  padding: 8px 16px;
  font-size: 14px;
}

.help-button:hover {
  background-color: #5a6268;
}

/* Update payroll controls to accommodate help button */
.payroll-controls {
  display: flex;
  gap: 10px;
  align-items: center;
}

.payroll-controls input[type="file"] {
  flex: 1;
}